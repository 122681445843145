import { Type } from 'class-transformer';
import { SocialAccount } from '../social-account';
import { TiktokPerformance } from './tiktok-performance';
export class TiktokInsightSnapshot {
  audience?: {
    audience_gender_age: any;
    audience_country: any;
    audience_city: any;
  };
  pr_media: { total: number; pr_media_count: number } | null = null;
  collected_at?: string;
}
export class TiktokAccount extends SocialAccount<TiktokPerformance> {
  @Type(() => TiktokPerformance)
  performances: TiktokPerformance[] = [];
  @Type(() => TiktokPerformance)
  latest_performance?: TiktokPerformance;
  id!: number;
  username!: string;
  biography?: string;
  insight_snapshot?: TiktokInsightSnapshot;
  need_reconnect?: boolean;
}
