import { InjectionToken, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstagramLogoComponent } from './components/logos/instagram-logo/instagram-logo.component';
import { TiktokLogoComponent } from './components/logos/tiktok-logo/tiktok-logo.component';
import { NewLinePipe } from './pipes/new-line.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SexPipe } from './pipes/sex.pipe';
import { PrefecturePipe } from './pipes/prefecture.pipe';
import { AgePipe } from './pipes/age.pipe';
import { PartnerPipe } from './pipes/partner.pipe';
import { WithChildrenPipe } from './pipes/with-children.pipe';
import { AgencyPipe } from './pipes/agency.pipe';
import { RewardTypePipe } from './pipes/reward-type.pipe';
import { MediumNamePipe } from './pipes/medium-name.pipe';
import { MessageBodyComponent } from './components/message-body/message-body.component';
import { windowProvider } from './factories/window.factory';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { BankAccountTypePipe } from './pipes/bank-account-type.pipe';
import { EscapeHtmlTagPipe } from './pipes/escape-html-tag.pipe';
import { RouterModule } from '@angular/router';
import { LinkifyPipe } from './pipes/linkify.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';

export const API_BASE_URL = new InjectionToken<string>('API_BASE_URL');
export const GOOGLE_MAP_API_KEY = new InjectionToken<string>('GOOGLE_MAP_API_KEY');

export interface CuepidCoreConfig {
  apiBaseUrl: string,
  googleMapApiKey?: string,
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
  ],
  declarations: [
    InstagramLogoComponent,
    TiktokLogoComponent,
    MessageBodyComponent,
    NewLinePipe,
    SafeHtmlPipe,
    SexPipe,
    PrefecturePipe,
    AgePipe,
    PartnerPipe,
    WithChildrenPipe,
    AgencyPipe,
    RewardTypePipe,
    MediumNamePipe,
    SafeUrlPipe,
    BankAccountTypePipe,
    EscapeHtmlTagPipe,
    LinkifyPipe,
    TimeAgoPipe,
  ],
  exports: [
    InstagramLogoComponent,
    TiktokLogoComponent,
    MessageBodyComponent,
    NewLinePipe,
    SafeHtmlPipe,
    SexPipe,
    PrefecturePipe,
    AgePipe,
    PartnerPipe,
    PartnerPipe,
    WithChildrenPipe,
    AgencyPipe,
    RewardTypePipe,
    MediumNamePipe,
    SafeUrlPipe,
    BankAccountTypePipe,
    EscapeHtmlTagPipe,
    LinkifyPipe,
    TimeAgoPipe,
    RouterModule,
  ],
  providers: [
    windowProvider,
  ],
  entryComponents: [
    InstagramLogoComponent,
    TiktokLogoComponent,
  ],
})
export class CuepidCoreModule {
  static forRoot(config: CuepidCoreConfig) {
    return {
      ngModule: CuepidCoreModule,
      imports: [
        CommonModule,
      ],
      providers: [
        {provide: API_BASE_URL, useValue: config.apiBaseUrl},
        {provide: GOOGLE_MAP_API_KEY, useValue: config.googleMapApiKey},
      ],
    }
  }
}
