export class Medium {
  static readonly INSTAGRAM = new Medium(1, 'Instagram');
  static readonly TIKTOK = new Medium(2, 'Tiktok');

  constructor(public readonly id: number, public readonly name: string) {
  }

  static all(): Medium[] {
    return [
      this.INSTAGRAM,
      this.TIKTOK,
    ];
  }
}
