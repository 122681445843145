import { Injectable } from '@angular/core';
import { RewardType } from '../constant/reward-type';
import { Medium } from '../models/medium';

@Injectable({
  providedIn: 'root'
})
export class MediumService {

  private readonly INSTAGRAM_REWARD_TYPES = [
    {code: 1, name: 'フォロワー数', operator: '×'},
    {code: 2, name: '平均いいね数', operator: '×'},
    {code: 3, name: '金額指定', operator: ''},
  ];

  private readonly TIKTOK_REWARD_TYPES = [
    {code: 1, name: 'フォロワー数', operator: '×'},
    {code: 2, name: '平均いいね数', operator: '×'},
    {code: 3, name: '金額指定', operator: ''},
  ];

  constructor() {
  }

  getMedium(mediumId: number | undefined): Medium | null {
    switch (mediumId) {
      case Medium.INSTAGRAM.id:
        return Medium.INSTAGRAM;
      case Medium.TIKTOK.id:
        return Medium.TIKTOK;
      default:
        return null;
    }
  }

  getRewardTypes(mediumId: number): RewardType[] | null {
    switch (mediumId) {
      case Medium.INSTAGRAM.id:
        return this.INSTAGRAM_REWARD_TYPES;
      case Medium.TIKTOK.id:
        return this.TIKTOK_REWARD_TYPES;
      default:
        return null;
    }
  }
}
